import React, { useState, useEffect, useRef } from "react";
import "../Styles/StoryField.css";

const StoryProps = (props) => {
    const Name = props.Name;
    const ProfilePicture = props.ProfilePictureURL

    return(
        <div className="story">
            <div className="ProfilePictureContainer"><img src={ProfilePicture} alt = "Profile Picture" className="ProfilePicture innerImage"/></div>
            <div className="NickName" id="Story">{Name}</div>
        </div>
    )
}


const StoriesField = () => {
    //Инициализация хуков
    const [isCollapsed, setIsCollapsed] = useState();
    const [stories, setStories] = useState([]);
    const containerRef = useRef();
    
    //Асинхронная функция, которая обращаяется к прокси серверу и получает из него данные ввиде JSON формата
    async function FetchData() {


        const requestURL = "http://localhost:5066/requestStoryJSON";

        try {
            const Response = await fetch(requestURL, {mode: "cors"});
            try {
                const data = await Response.json();
                setStories(data);   
            } catch (e) {
                throw "Unabable to read JSON response: " + e;
            }
        } catch (e) {
            throw "Failed Request to " + requestURL;
        }
        
    }
    
    

    //Выполнение запроса перед началом выполнения кода
    useEffect(() => {
        setIsCollapsed(true);
        console.log("Processing stories");
        FetchData();
    }, []);


    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    }

    const collapsed = isCollapsed ? "visibleContainer collapsed" : " visibleContainer notCollapsed";

    const renderStories = () => {
        
        if (stories.length === 0) {
            return <p>Loading...</p>
        } else {
            console.log("trying to render storyfield")
            const mappedStories = stories.Stories.map(item => <StoryProps Name = {item.Name} ProfilePictureURL = {item.ProfilePictureURL}/>)
            return(<>{mappedStories}</>)
        }
    }

    //Ветвление кода, чтобы не выводить не загруженные данные
        return(
                <div className="storyContainer">
                    <button type="checkbox" id="collapsible" className="collapsibleCheck" hidden onClick={toggleCollapse}/>
                    <p className="UI-text"><label for="collapsible" className="retractButton">ИСТОРИИ {isCollapsed ? <p>&uarr;</p> : <p>&darr;</p>}</label></p>
                    <div ref={containerRef} className="collapsible-content">
                        <div className={collapsed}>
                            {renderStories()}
                        </div>
                    </div>
                </div>
            )    
}

    


export default StoriesField;