import React from "react";
import "../Styles/ClosestEvent.css";
import placeholder3 from '../Styles/ava2.jpg'


const ClosestEvent = () => {

    //Делаем какой-то fetch


    const sampleData = 
    {"EventID": "EventID",
        "EventDetail": {
            "name" : "Фаст трек в Академию Инноваторов",
            "place" : "Онлайн",
            "time": "10.02.2025",
            "type": "Акселератор"
        },
        "EventMeta" : {
            "pictureUrl" : "sample",
            "fullLink" : "link"
        }
    }






    return(
        <>
        <div className="offerDetails">
            <img src={placeholder3} alt="event"/>
            <p classname='eventAuthor'><b>МТУСИ.Бизнеc</b></p>
        </div>
        <div className="event-data">
            <div className="nameDiscription">
                <p className="event Name">Название: <b>{sampleData.EventDetail.name}</b></p>
            </div>
            <div className="timePlaceType">
                <p className="event Time">Время: <b>{sampleData.EventDetail.time}</b></p>
                <p className="event Place">Место: <b>{sampleData.EventDetail.place}</b></p>
                <p className="event Type">Тип: <b>{sampleData.EventDetail.type}</b></p>
            </div>
        </div>        
        </>
    )
}

export default ClosestEvent;