import React from "react";
import placeholder from "../Styles/placeholder_photo.jpg";
import { useState } from "react";
import { ReactComponent as PlusIcon } from '../Styles/plus-svgrepo-com.svg';
import { ReactComponent as CheckIcon } from '../Styles/check-mark-svgrepo-com.svg';
import placeholder2 from "../Styles/ava1.jpg";
import placeholder3 from "../Styles/plac.jpg";

const Post = (props) => {
    var Name = props.Name;
    var FullName = props.FullName;
    var ProfilePictureURL = props.ProfilePictureURL;
    
    var EventName = props.EventName;
    var Description = props.Description;
    var Time = props.Time;

    const [subscribed, setSubscribed] = useState(false); // Состояние подписки

    const toggleSubscribe = () => {
        setSubscribed(!subscribed); // Переключение состояния
    };

    const buttonStyle = subscribed
        ? "actionButton subscribed"
        : "actionButton subscribe";

    const buttonText = subscribed ? "Subscribed" : "Subscribe";

    return(
        <div className="post">
        <div className="postHeader">
            <div className="postAuthor">
                <img src={placeholder2} alt="avatar"/>
                <div className="authorDetails">
                    <p className="nickname">Акселератор МТУСИ.Бизнес</p>
                    <p className="placeholderName">{FullName}</p>
                </div>
            </div>
        </div>
        <div className="postContent">
            <img src={placeholder3} alt="Placeholder image"/>
            <div className="postDetails">
                <p>{EventName}</p>
                <p className="description">
                <p><strong>Мы все этого долго ждали!</strong></p>
    <p><strong>Демо-день акселератора МТУСИ.Бизнес-2024❤️‍🔥</strong></p>

    <p><strong>В программе Демо-дня:</strong></p>
    <ul>
        <li> Презентация проектов финалистов Акселератора в формате питч-сессии;</li>
        <li> Общение с представителями вуза и корпораций;</li>
        <li> Награждение победителей и подведение итогов.</li>
    </ul>

    <p>Расскажем вам об экспертах, которые будут оценивать ваши проекты: 
    <strong>проректор по научной работе Ю.Л. Леохин</strong>, 
    <strong>заместитель директора ДИСиТ С.О. Зубов</strong>, 
    <strong>бизнес-партнер МТС по инновациям А.Ю. Панин</strong>, 
    <strong>менеджер проектного офиса Агентства инноваций Москвы И.А. Ченцов</strong> и другие.</p>

    <p><strong>Давайте сделаем этот день незабываемым⚡️</strong></p>
                </p>
                <p><b>Время:</b> 5.12.2024 16:00</p>
                <p><b>Место:</b> Авиамоторная улица, 8с39 УЛК-2</p>
                <div className="postActions">
                    <button className={buttonStyle} onClick={toggleSubscribe}>
                        {subscribed ? (
                            <>
                                Вы подписаны <CheckIcon className='iconCheck'/>
                            </>
                        ) : (
                            <>
                                Подписаться <PlusIcon className='iconPlus'/>
                            </>
                        )}
                    </button>
                </div>
            </div>

        </div>
    </div>
    );
}

const Tape = () => {
    return(
        <Post/>
    )
}


export default Tape;