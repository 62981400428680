
import React, { useEffect, useState, } from "react";
import "./Styles/authPage.css";
import { useNavigate } from "react-router";






const AuthPage = () => {

    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState();
    const [isFocused, setIsfocused] = useState();
    
    const HandleLoginRequest = async () => {
        const response = await fetch("http://localhost:5066/login/", 
            {
                method: "POST",
                mode: "cors",
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Access-Control-Allow-Headers': '*'
                        },
                body: JSON.stringify({
                    Name: document.getElementById("loginName").value,
                    Password: document.getElementById("loginPassword").value
                })
            });
            
        const data = await response.json();
        alert(JSON.stringify(data));
            if (response.ok === true) {
                localStorage.setItem("Credentials", data.acces_token);
                
                navigate("/");
            } else {
                alert("Something went wrong " + response.stringify);
            }
    }
    
    const HandleRegisterRequest = async () => {
        const response = await fetch("http://localhost:5066/Reg/", 
            {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Headers': '*'
                },
                body: JSON.stringify({
                    Name: document.getElementById("RegName").value,
                    Email: document.getElementById("RegEmail").value,
                    Password: document.getElementById("RegPassword").value
                })
            });
    
        if (response.ok === true) {
            alert("Register SuccesFul");
            if (localStorage.getItem("Credentials") == null){
                localStorage.setItem("Credentials", response.json());
            }
            navigate("/");
        } else {
            alert("Something went wrong");
        }
    }

    useEffect(() => {
        setIsfocused(false);
        setIsLogin(true);
    }, []);

    const HandleLogin = () => setIsLogin(true);
    const HandleRegister = () => setIsLogin(false);






    const handleFocus = () => setIsfocused(true);
    const handleBlur = () => setIsfocused(false);

    const focused = isFocused ? "focused" : "";
    return(
        <main className="authMain">
            <div className="mask">
                <div className={"authForm " + focused}>
                        <div className="authChanger">
                        <button onClick={HandleLogin} style={{
                            "font-size"  : "16pt",  
                            "color" : isLogin ? "black" : "red", 
                            "pointerEvents" : isLogin ? "none" : "all",}}>Log in</button>
                        <button onClick={HandleRegister} style={{
                            "font-size"  : "16pt",  
                            "color" : !isLogin ? "black" : "red", 
                            "pointerEvents" : !isLogin ? "none" : "all",}}>Sign up</button>
                        </div>
                        {isLogin ? 
                        <>
                        <form className="auth">
                            <input type="text" 
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            placeholder="Username"
                            id="loginName"/>
                            <input type="password" 
                            onFocus={handleFocus} 
                            onBlur={handleBlur}
                            placeholder="Password"
                            id="loginPassword"/>
                        </form>

                        <input type="Submit" className="submitAuth" onClick={HandleLoginRequest}/>
                            <p>No account? Then <a href="" onClick={HandleRegister}>Sign up</a></p>
                            

                        </> : <>
                            <form className="auth">
                                <input type="text" 
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                placeholder="Username"
                                id="RegName"/>
                                <input type="email" 
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                placeholder="Email"
                                id="RegEmail"/>
                                <input type="password" 
                                onFocus={handleFocus} 
                                onBlur={handleBlur}
                                placeholder="Password"

                                id="RegPassword"/>
                                
                            </form>
                            <input type="Submit" className="submitAuth" onClick={HandleRegisterRequest}/>

                            <div>
                                <div className="lawCheck">
                                    <input type="checkbox" id="terms"/>
                                    <label for="terms">I agree with terms of use</label>
                                </div>
                                <div className="lawCheck">
                                    <input type="checkbox" id="privacy"/>
                                    <label for="privacy">I agree with privacy policy</label>
                                </div>
                                
                            </div>

                        </>}
                </div>
            </div>
        </main>
    )
}

export default AuthPage;