import React, { useEffect } from "react";
import { useState } from "react";
const SideBarField = () => {
    const [isCollapsed, setIsCollapsed] = useState();
    useEffect(() => {
        setIsCollapsed(true)
    }, []);

    const HandleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    }

    const collapsed = isCollapsed ? "collased" : "notCollapsed";

    return(
        <>
        <div className="sideBar" id="main left">
            <button className="optionsButton">Главная</button>
            <button className="optionsButton">Публикации </button>
            <button className="optionsButton">Сообщения </button>            
        </div>
        </>
    )
}

export default SideBarField;