import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import IndexPage from "./ReactPages/indexPage/indexPage";
import AuthPage from "./ReactPages/AuthPage/AuthPage";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<IndexPage />} />
                <Route path="auth" element={<AuthPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;