import React, { useState, useEffect } from "react";
import "./Styles/App.css";
import Logo from "./Styles/Logo.svg";


import StoriesField from "./Elements/StoriesField";
import ProfileField from "./Elements/ProfileField";
import MakePost from "./Elements/MakePost";
import Tape from "./Elements/Tape";
import ClosestEvent from "./Elements/ClosestEvent";
import SideBarField from "./Elements/SideBarField";


const IndexPage = () => {
    // Состояние для темы, по умолчанию светлая тема
    const [isDarkMode, setIsDarkMode] = useState(false);

    const [isOpen, setIsOpen] = useState();

    useEffect(() => {
        const savedMode = localStorage.getItem("theme");
        if (savedMode === "dark") {
            setIsDarkMode(true);
        }


        setIsOpen(false);
    }, []);

    // Функция для переключения темы
    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
    };

    useEffect(() => {
        // Сохраняем тему в localStorage
        localStorage.setItem("theme", isDarkMode ? "dark" : "light");
    }, [isDarkMode]);


    
    const appClass = isDarkMode ? "App dark" : "App light";



    return (
        <div className={appClass}>
            <main className="mainWindow">
                <header className="App-header">
                    <div className="toolbarItem logo"><img src={Logo} alt="logo"/></div>
                    <div className="toolbarItem search">
                        <form className="Search-form">
                            <input className="searchBar" type="text" placeholder="Поиск" required/>
                            <input type='submit' hidden/>
                        </form>
                    </div>
                    <div className="toolbarItem person">
                        <ProfileField/>
                    </div>
                    <div className="toolbarItem">
                        <button onClick={toggleTheme} className="switchMode">{isDarkMode ? "To light mode": "To dark mode"}</button>


                    </div>
                </header>

                <contaier className="App-container">   
                    <StoriesField/>
                </contaier>


                <container className="postsAndOptions">
                    <div className="options">
                        <SideBarField/>
                    </div>
                    
                    <container className="posts">
                        <div className="tapeHeader">

                            <div className="closestEvent">
                                <ClosestEvent/>
                                <button className="MakeEventBtn" onClick={() => {setIsOpen(true)}}>Создать мероприятие</button>
                            </div>
                        </div>
                        <div className="Tape">
                            <Tape/>

                        </div>
                    </container>

                </container>                    

                <div className="ModalWindow" id="modal-window" style={isOpen ? {display : "block"} : {display : "none"}}>
                    <div className="MakePost">
                        <span className="close" style={{display: "flex", justifyContent : "space-between", fontSize :"16pt"}}><p>Введите ваши данные</p><p onClick={() => setIsOpen(false)}>&times;</p> </span>
                        <MakePost/>
                    </div>
                </div>

            </main>

        </div>
    );
}

export default IndexPage;