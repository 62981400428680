
import React, {useEffect, useState} from "react";
import "../Styles/MakePost.css";


const MakePost = () => {
    const [isLoading, setIsLoading] = useState();

    const PostData = async () => {
        setIsLoading(true)
        var datetime_local = document.getElementById("event-datetime-local").value;
        
        const Response = await fetch("http://localhost:5066/PostEvent", {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Access-Control-Allow-Credentials" : true,
                "Content-type" : "application/json",
            },
            body: JSON.stringify({
                Name: document.getElementById("event-name").value,
                Description: document.getElementById("event-description").value,
                Time: new Date(datetime_local).getTime(),
                File: document.getElementById("file-input").value
            })
        });
        
        if (Response.ok === true){
            setIsLoading(false);
            console.log("loading finished");
        }

    }

    const loading = isLoading ? "loading" : "";

    useEffect(() => {
        setIsLoading(false);
    }, [])
    return(
        <>
        <div className="EventData">
            <input type="text" placeholder="Название" id="event-name"/>
            <textarea type="textarea" placeholder="Описание" id="event-description"/>
            <input type="datetime-local" id="event-datetime-local"/>
        </div>
        <div className="EventDataFooter">
            <input type="file" hidden id="file-input"/>
            <label for="file-input">Добавить изображение</label>
            <button type="submit" onClick={PostData} className={"submitButton " + loading}> Создать</button>
        </div>
        
        </>
    )
}

export default MakePost;