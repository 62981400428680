
import React, { useState, useEffect } from "react";
import "../Styles/ProfileField.css";
import placeholder4 from "../Styles/ава2.jpg";

const Profile = (props) => {
    
    var Name = props.Name;
    var ProfilePicture = props.ProfilePicture;
    
    return (
            <>
            
            </>
    )
}

var data ="";
function ProfileField() {
    const [user, setUser] = useState({});

    
    async function FetchData(){
        
        try{

            const Response = await fetch("http://localhost:5066/request", {mode: "cors"});

            try{
                data = await Response.json();

                if (data.ProfilePictureUrl === "N/A") {
                    data.ProfilePictureUrl = "PLACEHOLDER";
                }

                setUser(data);
            } catch (e) {
                throw "Could not read response " + e;
            }
        } catch (e) { throw "Unexpected Request " + e;}
    }
    


    useEffect(() => {
        console.log('123')
        FetchData();
    }, [])

    return (<>
        {console.log(user)}
        <div className="ProfileField">
            <img src={placeholder4} alt="" className="ProfilePicture"/>
            <p className="UserName"><b>Иванов Иван</b></p>
        </div>
        </>)
}

export default ProfileField;